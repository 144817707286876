<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col>
        <h3>Create New Company</h3>
      </el-col>
    </el-row>

    <el-row class="company-form">
      <el-col>
        <company-form
          :form-data="form"
          :loading="loading"
          @save="create()"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BaseCompanyForm from './_components/forms/BaseCompanyForm'

import RooofAPI from '@/services/api/rooof'

import { createBlankCompany } from '@/utils/rooof'

export default {
  name: 'CompanyAdd',
  components: {
    'company-form': BaseCompanyForm
  },
  data () {
    return {
      form: createBlankCompany(),
      loading: false
    }
  },
  methods: {
    /**
     * Calls API to create a new company.
     */
    async create () {
      try {
        this.loading = true
        await RooofAPI.companies.create(this.form)

        // Update cached data before navigating back
        await this.$store.dispatch('fetch_company_list')
        this.$router.push({ name: 'CompanyList' })

        this.$message({
          message: `Company ${this.form.human_name} created`,
          type: 'success',
          duration: 3500
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.company-form {
  margin-top: 2em;
}
</style>
